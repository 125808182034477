import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Layout from '../components/Layout/Layout'
import Seo from "../components/Seo"
import MailSubscribe from '../components/Layout/MailSubscribe'
import { graphql, useStaticQuery } from 'gatsby'

const SubscribePage = () => {

    const data = useStaticQuery( graphql`
		query SubscribePage {
			page: wpPage( slug: { eq: "subscribe" } ) {
				... PageContent
			}
		}
	`)

    const seoTitle     = data.page.seo.title ? data.page.seo.title : data.page.title;

    return (
        <Layout>
            <Seo
				title={seoTitle}
				uri={data.page.uri}
				socialImage={data.page.featuredImage?.node}
				description={data.page.seo?.metaDesc}
			/>
			<StaticImage src='../../assets/images/page-bg.png' className="page-header-bg absolute top-0 left-0 right-0 z-0" alt="Page Header Background" />
            <div className="relative z-20">
                <h1 className="text-center pt-8 pb-0 lg:pt-16">Subscribe</h1>
                <p className="w-full lg:w-1/2 mx-auto px-4 text-center mb-12 text-xl">Subscribe to receive the latest news, sermons, articles, and more from Kevin DeYoung directly in your email inbox.</p>
                <div class="bg-green-900 px-12 py-4 pb-12 rounded-xl mx-auto mb-24 w-full lg:w-1/2">
                    <MailSubscribe />
                </div>
            </div>
        </Layout>
    )
}

export default SubscribePage